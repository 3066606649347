import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import apiAxios from "../../../config/ApiAxios";
import { useQuery } from "../../../utils/useQuery";
import { useHistory } from "react-router-dom";
import { handleGraficosParams } from "../../../utils/handleGraficosParams";

const PuntosDeVentas = () => {
  const transformarDatos = (data) => {
    const seriesData = [
      { name: "Prestado", group: "limiteMaximo", data: [] },
      { name: "Disponible", group: "limiteMaximo", data: [] },
      { name: "Capital a cobrar", group: "aCobrarEnTermino", data: [] },
      { name: "Interes a cobrar", group: "aCobrarEnTermino", data: [] },
      { name: "Punitorio a cobrar", group: "aCobrarEnTermino", data: [] },
      { name: "Capital en mora", group: "enMora", data: [] },
      { name: "Interes en mora", group: "enMora", data: [] },
      { name: "Punitorio en mora", group: "enMora", data: [] },
      { name: "Capital punitorio", group: "punitorio", data: [] },
      { name: "Interes punitorio", group: "punitorio", data: [] },
      { name: "Punitorio punitorio", group: "punitorio", data: [] },
    ];

    const puntosDeVenta = [];

    Object.values(data).forEach((puntoVenta) => {
      puntosDeVenta.push(
        puntoVenta.a_cobrar_en_termino?.[0]?.nombre || "Desconocido"
      );

      // Limite Maximo
      seriesData[0].data.push(puntoVenta.prestado);
      seriesData[1].data.push(puntoVenta.disponible);

      // aCobrarEnTermino
      seriesData[2].data.push(
        puntoVenta.a_cobrar_en_termino?.[0]?.total_capital || 0
      );
      seriesData[3].data.push(
        puntoVenta.a_cobrar_en_termino?.[0]?.total_interes || 0
      );
      seriesData[4].data.push(
        puntoVenta.a_cobrar_en_termino?.[0]?.total_interes_punitorio || 0
      );

      // enMora
      seriesData[5].data.push(puntoVenta.en_mora?.[0]?.total_capital || 0);
      seriesData[6].data.push(puntoVenta.en_mora?.[0]?.total_interes || 0);
      seriesData[7].data.push(
        puntoVenta.en_mora?.[0]?.total_interes_punitorio || 0
      );

      // prescripto (punitorio)
      seriesData[8].data.push(puntoVenta.prescripto?.[0]?.total_capital || 0);
      seriesData[9].data.push(puntoVenta.prescripto?.[0]?.total_interes || 0);
      seriesData[10].data.push(
        puntoVenta.prescripto?.[0]?.total_interes_punitorio || 0
      );
    });

    return { seriesData, puntosDeVenta };
  };

  const [series, setSeries] = useState([
    {
      name: "Prestado",
      group: "limiteMaximo",
      data: [0, 0],
    },
    {
      name: "Disponible",
      group: "limiteMaximo",
      data: [0, 0],
    },
    {
      name: "Cápital a cobrar",
      group: "aCobrarEnTermino",
      data: [0, 0],
    },
    {
      name: "Interes a cobrar",
      group: "aCobrarEnTermino",
      data: [0, 0],
    },
    {
      name: "Punitorio a cobrar",
      group: "aCobrarEnTermino",
      data: [0, 0],
    },
    {
      name: "Cápital en mora",
      group: "enMora",
      data: [0, 0],
    },
    {
      name: "Interes en mora",
      group: "enMora",
      data: [0, 0],
    },
    {
      name: "Punitorio en mora",
      group: "enMora",
      data: [0, 0],
    },
    {
      name: "Cápital punitorio",
      group: "punitorio",
      data: [0, 0],
    },
    {
      name: "Interes punitorio",
      group: "punitorio",
      data: [0, 0],
    },
    {
      name: "Punitorio punitorio",
      group: "punitorio",
      data: [0, 0],
    },
  ]);

  const [categories, setCategories] = useState([""]);

  useEffect(() => {
    const obtenerCreditos = async () => {
      try {
        const apiResponse = await apiAxios.get(`/reportes/global/puntos-venta`);

        const { seriesData, puntosDeVenta } = transformarDatos(
          apiResponse.data
        );

        if (apiResponse?.status === 200) {
          setSeries(seriesData);

          setCategories(puntosDeVenta);
        }
      } catch (e) {
        console.log(e);
      }
    };

    obtenerCreditos();
  }, []);

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      formatter: function (val, { seriesIndex, dataPointIndex }) {
        return new Intl.NumberFormat("es-ES", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(series[seriesIndex].data[dataPointIndex]);
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    tooltip: {
      y: {
        formatter: function (val, { seriesIndex, dataPointIndex }) {
          return new Intl.NumberFormat("es-ES", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(series[seriesIndex].data[dataPointIndex]);
        },
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        formatter: function (val) {
          return new Intl.NumberFormat("es-ES", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(val);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "#8ECEFF",
      "#008FFB",
      "#56FFC6",
      "#00E396",
      "#00E396",
      "#FDC862",
      "#FEB019",
      "#C78300",
      "#A190DE",
      "#775DD0",
      "#5437B5",
    ],
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };

  const modifiedSeries = series.map((serie) => ({
    ...serie,
    data: serie.data.map((val) => (val === 0 ? 400000 : val)),
  }));

  const query = useQuery();
  const history = useHistory();

  const ocultarGrafico = query.get("ocultarGrafico");

  const ocultarGraficoArray = ocultarGrafico
    ? ocultarGrafico.replace(/[[\]]/g, "").split(",").map(Number)
    : [];

  return (
    <Card
      style={{
        display: ocultarGraficoArray.includes(6) ? "none" : "block",
      }}
    >
      <Card.Body>
        <Card.Title>
          Puntos de ventas{" "}
          <button
            title="Ocultar"
            className="btn btn-light ms-2"
            onClick={() =>
              handleGraficosParams(5, ocultarGraficoArray, history)
            }
          >
            <i class="bi bi-eye"></i>
          </button>
        </Card.Title>
        <Chart
          options={options}
          series={modifiedSeries}
          type="bar"
          height={350}
          zoom={true}
        />
      </Card.Body>
    </Card>
  );
};

export default PuntosDeVentas;
