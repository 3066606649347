import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card } from "react-bootstrap";
import apiAxios from "../../../config/ApiAxios";
import { useQuery } from "../../../utils/useQuery";
import { useHistory } from "react-router-dom";
import { handleGraficosParams } from "../../../utils/handleGraficosParams";

const MorasPorPeriodo = () => {
  const transformarDatos = (data) => {
    const seriesData = [
      { name: "Capital", data: [] },
      { name: "Interes", data: [] },
      { name: "Punitorio", data: [] },
      { name: "Cuotas", data: [] },
    ];

    const fechaPeriodo = [];

    Object.values(data).forEach((periodo) => {
      fechaPeriodo.push(periodo.periodo || "Desconocido");

      seriesData[0].data.push(periodo.total_capital);
      seriesData[1].data.push(periodo.total_interes);
      seriesData[2].data.push(periodo.total_interes_punitorio);
      seriesData[3].data.push(periodo.cantidad_cuotas);
    });

    return { seriesData, fechaPeriodo };
  };

  const [series, setSeries] = useState([
    {
      data: [44, 55, 41, 64, 22, 43, 21],
    },
    {
      data: [53, 32, 33, 52, 13, 44, 32],
    },
    {
      data: [4, 66, 43, 63, 3, 6, 12],
    },
    {
      data: [5, 32, 33, 22, 4, 44, 1],
    },
  ]);

  const [categories, setCategories] = useState([""]);

  useEffect(() => {
    const obtenerCreditos = async () => {
      try {
        const apiResponse = await apiAxios.get(
          `/reportes/global/moras/por-periodo`
        );

        const { seriesData, fechaPeriodo } = transformarDatos(apiResponse.data);

        if (apiResponse?.status === 200) {
          setSeries(seriesData);
          setCategories(fechaPeriodo);
        }
      } catch (e) {
        console.log(e);
      }
    };

    obtenerCreditos();
  }, []);

  const options = {
    chart: {
      type: "bar",
      stacked: false,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    stroke: {
      width: 0,
      colors: ["#fff"],
    },
    dataLabels: {
      formatter: function (val, { seriesIndex, dataPointIndex }) {
        const value = series[seriesIndex].data[dataPointIndex];

        if (Number.isInteger(value)) {
          return new Intl.NumberFormat("es-ES", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(value);
        } else {
          return new Intl.NumberFormat("es-ES", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value);
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    tooltip: {
      y: {
        formatter: function (val, { seriesIndex, dataPointIndex }) {
          const value = series[seriesIndex].data[dataPointIndex];

          if (Number.isInteger(value)) {
            return new Intl.NumberFormat("es-ES", {
              style: "decimal",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(value);
          } else {
            return new Intl.NumberFormat("es-ES", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(value);
          }
        },
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        formatter: function (val) {
          return new Intl.NumberFormat("es-ES", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }).format(val);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: [
      "#8ECEFF",
      "#008FFB",
      "#56FFC6",
      "#00E396",
      "#00E396",
      "#FDC862",
      "#FEB019",
      "#C78300",
      "#A190DE",
      "#775DD0",
      "#5437B5",
    ],
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
  };

  const modifiedSeries = series.map((serie) => ({
    ...serie,
    data: serie.data.map((val) => (val < 40000 ? 40000 : val)),
  }));

  const query = useQuery();
  const history = useHistory();

  const ocultarGrafico = query.get("ocultarGrafico");

  const ocultarGraficoArray = ocultarGrafico
    ? ocultarGrafico.replace(/[[\]]/g, "").split(",").map(Number)
    : [];

  return (
    <Card
      style={{
        display: ocultarGraficoArray.includes(7) ? "none" : "block",
      }}
    >
      <Card.Body>
        <Card.Title>
          Moras por periodo
          <button
            title="Ocultar"
            className="btn btn-light ms-2"
            onClick={() =>
              handleGraficosParams(5, ocultarGraficoArray, history)
            }
          >
            <i class="bi bi-eye"></i>
          </button>
        </Card.Title>
        <Chart
          options={options}
          series={modifiedSeries}
          type="bar"
          height={1500}
          zoom={true}
        />
      </Card.Body>
    </Card>
  );
};

export default MorasPorPeriodo;
