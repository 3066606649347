export const handleGraficosParams = (id, ocultarGraficoArray, history) => {

  const scrollPosition = window.scrollY;

  const newArray = ocultarGraficoArray.includes(id)
    ? ocultarGraficoArray.filter((item) => item !== id)
    : [...ocultarGraficoArray, id];

  const newParams = `?ocultarGrafico=[${newArray.join(",")}]`;
  history.replace(newParams);
  window.scrollTo(0, scrollPosition);

};
