import { forwardRef } from "react";
import { Button } from "react-bootstrap";

export const DesdeButton = forwardRef(({ value, onClick, className }, ref) => (
    <Button className={className} onClick={onClick} ref={ref}>
      Desde {value} <i class="bi bi-calendar-week"></i>
    </Button>
  ));

export  const HastaButon = forwardRef(({ value, onClick, className }, ref) => (
    <Button className={className} onClick={onClick} ref={ref}>
      Hasta {value} <i class="bi bi-calendar-week"></i>
    </Button>
  ));