import "./ReportesGlobalesStyles.css";
import React from "react";
import ACobrarColumnas from "./aCobrarColumnas/ACobrarColumnas";
import ACobrarTorta from "./aCobrarTorta/ACobrarTorta";
import CreditosOtorgados from "./creditosOtorgados/CreditosOtorgados";
import FiltrosDrawer from "./filtrosDrawer/FiltrosDrawer";
import HeaderData from "./headerData/HeaderData";
import PuntosDeVentas from "./puntosDeVentas/PuntosDeVentas";
import PagosPorMontos from "./PagosPorMontos/PagosPorMontos";
import MorasPorPeriodo from "./morasPorPeriodo/MorasPorPeriodo";
import PagosPorMedios from "./porMedios/PagosPorMedios";


const ReportesGlobales = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <section className="container-lg contenedor-reportes-globales">
      <HeaderData />
      <div className="d-flex gap-2 cobrar-section">
        <ACobrarColumnas />
        <ACobrarTorta />
      </div>
      <hr />
      <div>
        <CreditosOtorgados />
      </div>
      <hr />
      <div>
        <PagosPorMontos />
      </div>
      <hr />
      <div>
        <PagosPorMedios />
      </div>
      <hr />
      <div>
        <PuntosDeVentas />
      </div>
      <hr />
      <div>
        <MorasPorPeriodo />
      </div>
      <hr />

      <button className="filtros bg-primary text-light">
        <i class="bi bi-sliders2"></i>
      </button>
      <FiltrosDrawer isOpen={isOpen} toggleDrawer={toggleDrawer} />
    </section>
  );
};

export default ReportesGlobales;
