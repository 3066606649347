import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FaArrowTrendDown, FaArrowTrendUp } from "react-icons/fa6";
import apiAxios from "../../../config/ApiAxios";

const HeaderData = () => {
  const [dataCards, setDataCards] = useState({
    cobrado: {
      cantidad_principal: 903,
      cantidad_comparativa: 1500,
      descripcion: "597 menos respecto al día anterior",
    },
    en_mora: {
      cantidad_principal: 3780,
      cantidad_comparativa: 1000,
      descripcion: "2780 más respecto al día anterior",
    },
    prescripto: {
      cantidad_principal: 366,
      cantidad_comparativa: 366,
      descripcion: "sin variaciones respecto al día anterior",
    },
  });

  useEffect(() => {
    const obtenerCreditos = async () => {
      try {
        const apiResponse = await apiAxios.get("/reportes/global/resumen");

        if (apiResponse?.status === 200) {
          setDataCards(apiResponse?.data);
        }
      } catch (e) {
        console.log(e);
      }
    };

    obtenerCreditos();

    return () => {
      setDataCards({});
    };
  }, []);

  return (
    <div className="header-container">
      <Card style={{ width: "100%" }}>
        <Card.Body>
          <Card.Title>Cobrado</Card.Title>
          <Card.Text as="h4" className="data">
            {dataCards?.cobrado?.cantidad_principal} Créditos
          </Card.Text>
          <Card.Text
            as="span"
            className={`comparacion ${
              dataCards?.cobrado?.descripcion?.includes("menos")
                ? "negativo"
                : "positivo"
            } `}
          >
            {dataCards?.cobrado?.descripcion}{" "}
            {dataCards?.cobrado?.descripcion?.includes("menos") ? (
              <FaArrowTrendDown />
            ) : (
              <FaArrowTrendUp />
            )}
          </Card.Text>
        </Card.Body>
      </Card>

      <Card style={{ width: "100%" }}>
        <Card.Body>
          <Card.Title>En mora</Card.Title>
          <Card.Text as="h4" className="data">
            {dataCards?.en_mora?.cantidad_principal} Créditos
          </Card.Text>
          <Card.Text
            as="span"
            className={`comparacion ${
              dataCards?.en_mora?.descripcion?.includes("más")
                ? "negativo"
                : "positivo"
            } `}
          >
            {dataCards?.en_mora?.descripcion}{" "}
            {dataCards?.en_mora?.descripcion?.includes("menos") ? (
              <FaArrowTrendDown />
            ) : (
              <FaArrowTrendUp />
            )}
          </Card.Text>
        </Card.Body>
      </Card>

      <Card style={{ width: "100%" }}>
        <Card.Body>
          <Card.Title>Prescripto</Card.Title>
          <Card.Text as="h4" className="data">
            {dataCards?.prescripto?.cantidad_principal} Créditos
          </Card.Text>
          <Card.Text
            as="span"
            className={`comparacion ${
              dataCards?.prescripto?.descripcion?.includes("menos")
                ? "negativo"
                : "positivo"
            } `}
          >
            {dataCards?.prescripto?.descripcion}{" "}
            {dataCards?.prescripto?.descripcion?.includes("menos") ? (
              <FaArrowTrendDown />
            ) : (
              <FaArrowTrendUp />
            )}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default HeaderData;
