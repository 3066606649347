import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { handleGraficosParams } from "../../../utils/handleGraficosParams";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../../utils/useQuery";
import "./filtrosDrawer.css";
import DatePicker, { registerLocale } from "react-datepicker";
import { es } from "date-fns/locale";
import { DesdeButton, HastaButon } from "../DesdeYHastaButtons";
import { format } from "date-fns";

registerLocale("es", es);

const FiltrosDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const query = useQuery();
  const history = useHistory();

  const ocultarGrafico = query.get("ocultarGrafico");

  const ocultarGraficoArray = ocultarGrafico
    ? ocultarGrafico.replace(/[[\]]/g, "").split(",").map(Number)
    : [];

  const [startDate, setStartDate] = useState(new Date("2023/02/08"));
  const [endDate, setEndDate] = useState(new Date());

  const updateQueryParams = (startDate, endDate) => {
    const formattedStartDate = format(startDate, "dd-MM-yyyy", { locale: es });
    const formattedEndDate = format(endDate, "dd-MM-yyyy", { locale: es });

    const searchParams = new URLSearchParams({
      "fecha-inicio": formattedStartDate,
      "fecha-final": formattedEndDate,
    });

    history.push({
      search: searchParams.toString(),
    });
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    updateQueryParams(date, endDate);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    updateQueryParams(startDate, date);
  };

  return (
    <>
      <button
        type="button"
        onClick={toggleDrawer}
        className="filtros bg-primary text-light"
      >
        <i class="bi bi-sliders2"></i>
      </button>
      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="drawer-graficos"
        size={400}
      >
        <div className="contenedor-filtros">
          <h4>Ocultar/mostrar graficos</h4>
          <label role="button" className="mb-3 d-flex align-items-center gap-3">
            <input
              type="checkbox"
              onChange={(e) => {
                handleGraficosParams(1, ocultarGraficoArray, history);
              }}
              checked={!ocultarGraficoArray.includes(1)}
            />
            <span>A cobrar (Columnas)</span>
          </label>

          <label role="button" className="mb-3 d-flex align-items-center gap-3">
            <input
              type="checkbox"
              onChange={(e) => {
                handleGraficosParams(2, ocultarGraficoArray, history);
              }}
              checked={!ocultarGraficoArray.includes(2)}
            />
            <span>A cobrar (Torta)</span>
          </label>

          <label role="button" className="mb-3 d-flex align-items-center gap-3">
            <input
              type="checkbox"
              onChange={(e) => {
                handleGraficosParams(3, ocultarGraficoArray, history);
              }}
              checked={!ocultarGraficoArray.includes(3)}
            />
            <span>Créditos otorgados</span>
          </label>

          <label role="button" className="mb-3 d-flex align-items-center gap-3">
            <input
              type="checkbox"
              onChange={(e) => {
                handleGraficosParams(4, ocultarGraficoArray, history);
              }}
              checked={!ocultarGraficoArray.includes(4)}
            />
            <span>Pagos por monto</span>
          </label>

          <label role="button" className="mb-3 d-flex align-items-center gap-3">
            <input
              type="checkbox"
              onChange={(e) => {
                handleGraficosParams(5, ocultarGraficoArray, history);
              }}
              checked={!ocultarGraficoArray.includes(5)}
            />
            <span>Pagos por medio</span>
          </label>

          <label role="button" className="mb-3 d-flex align-items-center gap-3">
            <input
              type="checkbox"
              onChange={(e) => {
                handleGraficosParams(6, ocultarGraficoArray, history);
              }}
              checked={!ocultarGraficoArray.includes(6)}
            />
            <span>Puntos de ventas</span>
          </label>

          <label role="button" className="mb-3 d-flex align-items-center gap-3">
            <input
              type="checkbox"
              onChange={(e) => {
                handleGraficosParams(7, ocultarGraficoArray, history);
              }}
              checked={!ocultarGraficoArray.includes(7)}
            />
            <span>Moras por periodo</span>
          </label>
          <hr />
          <div className="mb-5"></div>
          <h4>Establece rango de fechas en simultaneo</h4>

          <DatePicker
            locale={"es"}
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat={"dd/MM/yyyy"}
            customInput={<DesdeButton className="" />}
          />
          <div className="mb-3"></div>
          <DatePicker
            locale={"es"}
            selectsEnd
            selected={endDate}
            onChange={handleEndDateChange}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat={"dd/MM/yyyy"}
            customInput={<HastaButon className="me-3" />}
          />
        </div>
      </Drawer>
    </>
  );
};

export default FiltrosDrawer;
